import React from "react";

import cn from "classnames";
import { Carousel } from "react-responsive-carousel";

import Image from "../Image";

import styles from "./CustomCarousel.module.sass";

const CustomCarousel = () => {
  return (
    <Carousel
      className={styles.carousel}
      infiniteLoop
      autoPlay
      interval={2500}
      emulateTouch
      showArrows={false}
      showIndicators={false}
      showStatus={false}
    >
      <div className={styles.carouselItem}>
        <div className={styles.rating}>
          <Image
            src="images/content/star-filled.png"
            className={styles.ratingStar}
          />
          <Image
            src="images/content/star-filled.png"
            className={styles.ratingStar}
          />
          <Image
            src="images/content/star-filled.png"
            className={styles.ratingStar}
          />
          <Image
            src="images/content/star-filled.png"
            className={styles.ratingStar}
          />
          <Image
            src="images/content/star-filled.png"
            className={styles.ratingStar}
          />
        </div>
        <div className={cn("h4", styles.quote)}>
          “Everything is easy, from creating the Teams to checking on how the
          employees are collaborating”
        </div>

        <div className={styles.quoteAuthor}>
          <div className={styles.line}></div>
          <div className={styles.text}>
            Zlatina Stoeva{" "}
            <span>| Head of Talent Operations at Xogito Group</span>
          </div>
        </div>

        <Image
          className={styles.carouselPic}
          src="/images/content/home.png"
          alt="Home Screen"
        />
      </div>
      <div className={styles.carouselItem}>
        <div className={styles.wrap}>
          <div className={cn("h4", styles.carouselTitle)}>
            All your operations in one, easy-to-manage place.
          </div>
          <div className={styles.groupPic}>
            <Image
              src="/images/content/group.png"
              alt="Sign Up"
            />
          </div>
          <p className={styles.carouselSubtitle}>
            As an executive, manager or business leader, Metra has everything
            covered so you don’t ever miss the big picture: <br />
            <span>Produce meaningful work.</span>
          </p>
        </div>
      </div>
    </Carousel>
  );
};

export default CustomCarousel;

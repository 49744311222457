import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as GlobalIcon } from "assets/icons/global.svg";
import { ReactComponent as GroupIcon } from "assets/icons/group.svg";
import { ReactComponent as SegmentIcon } from "assets/icons/segment.svg";
import { Autocomplete, Checkbox, Loader, TextInput } from "components";
import useDebounce from "hooks/useDebounce";
import { getCurrentTimezone } from "slices/companySlice";
import { signUpValidationSchema } from "validations/authValidations";

import styles from "./Entry.module.sass";

const companySegments = [
  "Healthcare",
  "Technology",
  "Financial Services",
  "Retail",
  "Manufacturing",
  "Telecommunications",
  "Transportation and Logistics",
  "Energy and Utilities",
  "Real Estate",
  "Education",
  "Hospitality and Tourism",
  "Media and Entertainment",
  "Consumer Goods",
  "Automotive",
  "Pharmaceuticals",
  "Construction",
  "Agriculture",
  "Professional Services",
  "Government and Public Sector",
  "Nonprofit Organizations",
];

const numberOfEmployees = [
  "Just the founding team",
  "Less than 5",
  "Between 5-10",
  "Between 11-20",
  "Between 21-50",
  "Between 51-75",
  "Between 76-100",
  "More than 100",
];

const Entry = ({ onConfirm, title }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    email: "",
    username: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    companyName: "",
    companySegment: "",
    timezone: "",
    companySize: "",
    terms: false,
  });
  const [touchedFields, setTouchedFields] = useState({});
  const debouncedValues = useDebounce(values, 500);
  const { isLoggedIn, error, loading } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { timezones } = useSelector((state) => state.company);
  const { currentTimezone } = useSelector((state) => state.company);

  useEffect(() => {
    if (currentTimezone) {
      setValues((prevValues) => ({
        ...prevValues,
        timezone: currentTimezone,
      }));
    }
  }, [currentTimezone]);

  const validateFields = async () => {
    try {
      const nonEmptyValues = Object.keys(values).reduce((acc, key) => {
        if (values[key].trim() !== "") {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      if (!values.terms) {
        throw new Error("You must agree to the Terms and Conditions.");
      }

      await signUpValidationSchema.validate(nonEmptyValues, {
        abortEarly: false,
      });
      setErrors({});
    } catch (err) {
      const fieldErrors = err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      setErrors(fieldErrors);
    }
  };

  const validateField = async (name, value) => {
    if (value.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
      return;
    }

    try {
      const tempValues = { ...values, [name]: value };
      await signUpValidationSchema.validate(tempValues, { abortEarly: false });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (err) {
      const fieldErrors = err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldErrors[name] }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = value;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));

    if (touchedFields[name]) {
      validateField(name, newValue);
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = !values.terms;
    setValues((prevValues) => ({
      ...prevValues,
      terms: isChecked,
    }));

    if (touchedFields.terms) {
      validateField("terms", isChecked);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));
    validateField(name, value);
  };

  useEffect(() => {
    dispatch(getCurrentTimezone());
    // getTimezones();
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, global: error }));
    }
  }, [error]);

  useEffect(() => {
    if (!error) {
      setErrors((prevErrors) => ({ ...prevErrors, global: undefined }));
    }
  }, [error]);

  useEffect(() => {
    validateFields();
  }, [debouncedValues]);

  return (
    <>
      <div className={cn("h4", styles.title)}>{title}</div>
      <div className={styles.entry}>
        <div className={styles.body}>
          <div className={styles.info}>
            Share a few details so we can tailor your experience.
          </div>
          {errors.global && (
            <div className={styles.errorNote}>{errors.global}</div>
          )}
          <hr className={styles.horizontalLine} />
          <div className={styles.fieldContainer}>
            {touchedFields.username && errors.username && (
              <div className={styles.errorNote}>{errors.username}</div>
            )}
            <TextInput
              className={styles.field}
              name="username"
              type="text"
              placeholder="Full Name*"
              required
              icon="profile-circle"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.fieldContainer}>
            {touchedFields.email && errors.email && (
              <div className={styles.errorNote}>{errors.email}</div>
            )}
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Work Email*"
              required
              icon="mail"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.fieldContainer}>
            {touchedFields.password && errors.password && (
              <div className={styles.errorNote}>{errors.password}</div>
            )}
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password*"
              required
              icon="lock"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.fieldContainer}>
            {touchedFields.passwordConfirmation &&
              errors.passwordConfirmation && (
                <div className={styles.errorNote}>
                  {errors.passwordConfirmation}
                </div>
              )}
            <TextInput
              className={styles.field}
              name="passwordConfirmation"
              type="password"
              placeholder="Confirm Password*"
              required
              icon="lock"
              value={values.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.fieldContainer}>
            {touchedFields.phone && errors.phone && (
              <div className={styles.errorNote}>{errors.phone}</div>
            )}
            <TextInput
              className={styles.field}
              name="phone"
              type="text"
              placeholder="Phone"
              icon="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.fieldContainer}>
            {touchedFields.companyName && errors.companyName && (
              <div className={styles.errorNote}>{errors.companyName}</div>
            )}
            <TextInput
              className={styles.field}
              name="companyName"
              type="text"
              placeholder="Company Name*"
              required
              customIcon={BriefcaseIcon}
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <Autocomplete
            placeholder="Company Segment"
            className={styles.autocompleteField}
            classDropdownHead={styles.dropdownHead}
            value={values.companySegment}
            setValue={(value) => {
              setValues({
                ...values,
                companySegment: value,
              });
            }}
            options={companySegments}
            name="companySegment"
            customIcon={SegmentIcon}
            tooltip="-"
          />
          <Autocomplete
            placeholder="Timezone*"
            className={styles.autocompleteField}
            classDropdownHead={styles.dropdownHead}
            value={values.timezone}
            setValue={(value) => {
              setValues({
                ...values,
                timezone: value,
              });
            }}
            options={timezones}
            name="timezone"
            customIcon={GlobalIcon}
            tooltip="-"
          />
          <Autocomplete
            placeholder="Number of Employees"
            className={styles.autocompleteField}
            classDropdownHead={styles.dropdownHead}
            value={values.companySize}
            setValue={(value) => {
              setValues({
                ...values,
                companySize: value,
              });
            }}
            options={numberOfEmployees}
            name="companySize"
            customIcon={GroupIcon}
            tooltip="-"
          />
          <div className={styles.agreement}>
            <Checkbox
              name="terms"
              className={styles.agreementCheckbox}
              checked={values.terms}
              onChange={handleCheckboxChange}
              onBlur={handleBlur}
            />
            <span className={styles.agreementText}>
              By checking this box, you agree to the adherence of Terms and
              Conditions in accordance to{" "}
              <Link
                to="/sign-up/privacy-policy"
                className={styles.privacy}
              >
                Privacy Policy
              </Link>
              .
            </span>
            {!values.terms && touchedFields.terms && (
              <div className={styles.errorNote}>
                You must agree to the Terms and Conditions.
              </div>
            )}
          </div>

          <button
            className={cn("button", styles.button, {
              disabled:
                !values.username ||
                !values.email ||
                !values.password || 
                !values.passwordConfirmation || 
                !values.companyName ||
                !values.terms || 
                Object.values(errors).some((err) => err !== undefined),
            })}
            onClick={() => onConfirm(values)}
          >
            {loading && (
              <Loader
                className={styles.loader}
                white
              />
            )}
            <span>Sign Up</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Entry;
